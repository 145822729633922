body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


.spinner-wrapper {
  text-align: center;
  color: rgb(207, 27, 40);
  margin-top: 1rem;
}

.outlet-with-sidebar {
  margin-left: 20%;
  padding: 20px 0px;
}

.outlet-without-sidebar {
  margin-left: 0%;
  padding: 24px;
  padding-bottom: 0;
}