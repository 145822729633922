.login-screen {
  /*background-image: url('../../../public/vlu-view.png');*/
}

.login-card {
  width: 500px;
  height: 400px;
}

.login-vlu {
  padding-top: 10px;
  padding-bottom: 20px;
  cursor: pointer;
}

.login-button {
  margin-top: 20px;
  width: 100%;
}

.login-border-vlu {
  border-bottom: dashed 2px;
  margin-bottom: 20px;
}

.logo-vlu {
  float: left;
  position: absolute;
  padding-top: 25px;
}

.form-vlu {
  right: 30px;
  float: right;
  position: absolute;
  padding-top: 35px;
}
