/*html {*/
/*  font-size: 62.5%;*/
/*}*/

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #333;
  background-color: #fff;
  font-size: 1.8rem;
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: #f58021;
}

a:hover {
  text-decoration: underline;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 767px) {
  .only-pc,
  .hide-sp {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .only-sp,
  .hide-pc {
    display: none !important;
  }
}

.container {
  max-width: 1300px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

#main {
  overflow: hidden;
}

#site-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999999;
  background-color: #fff;
  filter: drop-shadow(1px 1px 1px #ccc);
}

#site-header .header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 767px) {
  #site-header .header-wrap {
    flex-direction: column;
    padding: 1rem 1.5rem;
  }

  #site-header .header-wrap .box-menu {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}

@media screen and (min-width: 768px) {
  #site-header .header-wrap {
    padding: 1.5rem 2rem;
  }
}

#logo img {
  display: block;
}

#menu-primary {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 767px) {
  #menu-primary {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  #menu-primary li {
    padding: 0 1rem;
  }
}

#menu-primary li a {
  padding: 1rem;
  color: inherit;
  font-weight: bold;
  display: block;
}

@media screen and (max-width: 767px) {
  #menu-primary li a {
    padding: 2rem 1rem;
  }
}

@media screen and (min-width: 768px) {
  #menu-primary li a {
    padding: 1rem;
    font-size: 2rem;
  }
}

#site-footer {
  filter: drop-shadow(0 0.1rem 0.1rem #363a45);
  background-color: #fff;
}

#footer-wrap {
  display: flex;
  justify-content: space-between;
  padding: 5rem;
}

@media screen and (max-width: 767px) {
  #footer-wrap {
    gap: 3rem;
    flex-direction: column;
    padding: 5rem 2rem;
  }
}

#footer-wrap .footer-widget .footer-widget-title {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  #footer-wrap .footer-widget-menu {
    padding-left: 5rem;
    width: auto;
  }
}

#footer-wrap .footer-widget-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 768px) {
  #menu-mobile {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  #menu-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-around;
    background-color: #fff;
    padding: 0.5rem 0;
  }

  #menu-mobile li a {
    display: block;
    padding: 1rem;
    border: 1px solid #f58021;
    border-radius: 0.5rem;
  }
}

#footer-website {
  background-color: #363a45;
  color: #fff;
}

@media screen and (max-width: 767px) {
  #footer-website {
    padding-bottom: 5rem;
  }
}

#footer-website .footer-website-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}

@media screen and (max-width: 767px) {
  #footer-website .footer-website-wrap {
    flex-direction: column;
    gap: 2rem;
    padding: 3rem 1.5rem;
  }
}

#footer-website .footer-website-wrap > div {
  width: 100%;
  text-align: center;
}

#footer-website .footer-website-wrap #menu-footer {
  display: flex;
  padding: 0;
  margin: 0;
  gap: 2rem;
  font-size: 1.1em;
}

@media screen and (max-width: 767px) {
  #footer-website .footer-website-wrap #menu-footer {
    justify-content: center;
  }
}

#footer-website .footer-website-wrap #menu-footer li {
  padding-right: 0.5rem;
}

#footer-website .footer-website-wrap #menu-footer li:first-child {
  list-style: none;
}

#footer-website .footer-website-wrap #menu-footer a {
  color: inherit;
}

#footer-website .footer-website-wrap .copyright {
  font-size: 0.9em;
  color: #ccc;
}

#footer-website .footer-website-wrap .apps ul {
  display: flex;
  justify-content: end;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: auto;
  gap: 2rem;
}

@media screen and (max-width: 767px) {
  #footer-website .footer-website-wrap .apps ul {
    margin-right: auto;
    justify-content: center;
  }
}

#footer-website .footer-website-wrap .apps ul img {
  height: 4rem;
  width: auto;
}

#top-content {
  padding: 7rem 0;
  background: #f58021;
  color: #fff;
}

#top-content .top-content-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
}

@media screen and (max-width: 767px) {
  #top-content .top-content-wrap {
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  #top-content .top-content-wrap .content {
    width: calc(100% - 40rem);
  }
}

@media screen and (min-width: 768px) {
  #top-content .top-content-wrap .img {
    width: 35rem;
  }
}

#top-content .top-content-wrap .img img {
  margin: 0 auto;
  width: 25rem;
  border: 0.4rem solid #363a45;
  border-radius: 2rem;
}

.section {
  padding: 7rem 0;
}

.section:nth-child(2n+3) {
  background-color: #f9f9f9;
}

.section-help .title {
  font-size: 2em;
  margin-top: 0;
}

@media screen and (max-width: 767px) {
  .section-help .title {
    font-size: 1.4em;
  }
}

.section-help ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.section-help ul li a {
  display: block;
  color: inherit;
  padding: 1rem 3rem;
  border: 1px solid #e5e1e1;
  border-radius: 1rem;
  transition: 0.3s;
}

@media screen and (min-width: 768px) {
  .section-help ul li a {
    font-size: 1.4em;
  }
}

.section-help ul li a:hover {
  text-decoration: none;
  background-color: #f58021;
  color: #fff;
}

#post-title {
  color: #f58021;
  text-align: center;
}

#menu-support {
  display: flex;
}

@media screen and (max-width: 767px) {
  #menu-support {
    flex-direction: column;
  }
}

#menu-support li {
  padding: 0 2rem;
}

#menu-support li a {
  padding: 2rem;
  display: block;
  color: inherit;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  #menu-support li a {
    padding: 2rem 0;
  }
}

.wpcf7-form {
  max-width: 500px;
  margin: 0 auto;
}

.wpcf7-form label {
  font-weight: bold;
}

.wpcf7-form input {
  font: inherit;
  min-height: 4rem;
  box-sizing: border-box;
  display: block;
  width: 100%;
  border: 1px solid #ccc;
  padding: 0.75rem 1.5rem;
}

.wpcf7-form input.wpcf7-submit {
  background-color: #f58021;
  color: #fff;
  font-size: 1.2em;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  padding: 1rem;
  padding-top: 1.2rem;
}

.aligncenter {
  margin: 0 auto;
}

.wpcf7 .screen-reader-response {
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
}

.wpcf7 form .wpcf7-response-output {
  margin: 2em 0.5em 1em;
  padding: 0.2em 1em;
  border: 2px solid #00a0d2; /* Blue */
}

.wpcf7 form.init .wpcf7-response-output,
.wpcf7 form.resetting .wpcf7-response-output,
.wpcf7 form.submitting .wpcf7-response-output {
  display: none;
}

.wpcf7 form.sent .wpcf7-response-output {
  border-color: #46b450; /* Green */
}

.wpcf7 form.failed .wpcf7-response-output,
.wpcf7 form.aborted .wpcf7-response-output {
  border-color: #dc3232; /* Red */
}

.wpcf7 form.spam .wpcf7-response-output {
  border-color: #f56e28; /* Orange */
}

.wpcf7 form.invalid .wpcf7-response-output,
.wpcf7 form.unaccepted .wpcf7-response-output,
.wpcf7 form.payment-required .wpcf7-response-output {
  border-color: #ffb900; /* Yellow */
}

.wpcf7-form-control-wrap {
  position: relative;
}

.wpcf7-not-valid-tip {
  color: #dc3232; /* Red */
  font-size: 1em;
  font-weight: normal;
  display: block;
}

.use-floating-validation-tip .wpcf7-not-valid-tip {
  position: relative;
  top: -2ex;
  left: 1em;
  z-index: 100;
  border: 1px solid #dc3232;
  background: #fff;
  padding: .2em .8em;
  width: 24em;
}

.wpcf7-list-item {
  display: inline-block;
  margin: 0 0 0 1em;
}

.wpcf7-list-item-label::before,
.wpcf7-list-item-label::after {
  content: " ";
}

.wpcf7-spinner {
  visibility: hidden;
  display: inline-block;
  background-color: #23282d; /* Dark Gray 800 */
  opacity: 0.75;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 100%;
  padding: 0;
  margin: 0 24px;
  position: relative;
}

form.submitting .wpcf7-spinner {
  visibility: visible;
}

.wpcf7-spinner::before {
  content: '';
  position: absolute;
  background-color: #fbfbfc; /* Light Gray 100 */
  top: 4px;
  left: 4px;
  width: 6px;
  height: 6px;
  border: none;
  border-radius: 100%;
  transform-origin: 8px 8px;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@media (prefers-reduced-motion: reduce) {
  .wpcf7-spinner::before {
    animation-name: blink;
    animation-duration: 2000ms;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.wpcf7 input[type="file"] {
  cursor: pointer;
}

.wpcf7 input[type="file"]:disabled {
  cursor: default;
}

.wpcf7 .wpcf7-submit:disabled {
  cursor: not-allowed;
}

.wpcf7 input[type="url"],
.wpcf7 input[type="email"],
.wpcf7 input[type="tel"] {
  direction: ltr;
}

.wpcf7-reflection > output {
  display: list-item;
  list-style: none;
}

.wpcf7-reflection > output[hidden] {
  display: none;
}


img.wp-smiley, img.emoji {
  display: inline !important;
  border: none !important;
  box-shadow: none !important;
  height: 1em !important;
  width: 1em !important;
  margin: 0 0.07em !important;
  vertical-align: -0.1em !important;
  background: none !important;
  padding: 0 !important;
}

body {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, rgb(155, 81, 224) 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, rgb(122, 220, 180) 0%, rgb(0, 208, 130) 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, rgb(207, 46, 46) 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, rgb(238, 238, 238) 0%, rgb(169, 184, 195) 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, rgb(74, 234, 220) 0%, rgb(151, 120, 209) 20%, rgb(207, 42, 186) 40%, rgb(238, 44, 130) 60%, rgb(251, 105, 98) 80%, rgb(254, 248, 76) 100%);
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, rgb(255, 206, 236) 0%, rgb(152, 150, 240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, rgb(254, 205, 165) 0%, rgb(254, 45, 45) 50%, rgb(107, 0, 62) 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, rgb(255, 203, 112) 0%, rgb(199, 81, 192) 50%, rgb(65, 88, 208) 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg, rgb(255, 245, 203) 0%, rgb(182, 227, 212) 50%, rgb(51, 167, 181) 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg, rgb(202, 248, 128) 0%, rgb(113, 206, 126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg, rgb(2, 3, 129) 0%, rgb(40, 116, 252) 100%);
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
  --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}

:where(.is-layout-flex) {
  gap: 0.5em;
}

:where(.is-layout-grid) {
  gap: 0.5em;
}

body .is-layout-flow > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-flow > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-flow > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignleft {
  float: left;
  margin-inline-start: 0;
  margin-inline-end: 2em;
}

body .is-layout-constrained > .alignright {
  float: right;
  margin-inline-start: 2em;
  margin-inline-end: 0;
}

body .is-layout-constrained > .aligncenter {
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > :where(:not(.alignleft):not(.alignright):not(.alignfull)) {
  max-width: var(--wp--style--global--content-size);
  margin-left: auto !important;
  margin-right: auto !important;
}

body .is-layout-constrained > .alignwide {
  max-width: var(--wp--style--global--wide-size);
}

body .is-layout-flex {
  display: flex;
}

body .is-layout-flex {
  flex-wrap: wrap;
  align-items: center;
}

body .is-layout-flex > * {
  margin: 0;
}

body .is-layout-grid {
  display: grid;
}

body .is-layout-grid > * {
  margin: 0;
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}

:where(.wp-block-columns.is-layout-grid) {
  gap: 2em;
}

:where(.wp-block-post-template.is-layout-flex) {
  gap: 1.25em;
}

:where(.wp-block-post-template.is-layout-grid) {
  gap: 1.25em;
}

.has-black-color {
  color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-color {
  color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-color {
  color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-color {
  color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-color {
  color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-color {
  color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-color {
  color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-color {
  color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-color {
  color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-color {
  color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-color {
  color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-color {
  color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-background-color {
  background-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-background-color {
  background-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-background-color {
  background-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-background-color {
  background-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-background-color {
  background-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-background-color {
  background-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-background-color {
  background-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-background-color {
  background-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-background-color {
  background-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-background-color {
  background-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-background-color {
  background-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-black-border-color {
  border-color: var(--wp--preset--color--black) !important;
}

.has-cyan-bluish-gray-border-color {
  border-color: var(--wp--preset--color--cyan-bluish-gray) !important;
}

.has-white-border-color {
  border-color: var(--wp--preset--color--white) !important;
}

.has-pale-pink-border-color {
  border-color: var(--wp--preset--color--pale-pink) !important;
}

.has-vivid-red-border-color {
  border-color: var(--wp--preset--color--vivid-red) !important;
}

.has-luminous-vivid-orange-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-orange) !important;
}

.has-luminous-vivid-amber-border-color {
  border-color: var(--wp--preset--color--luminous-vivid-amber) !important;
}

.has-light-green-cyan-border-color {
  border-color: var(--wp--preset--color--light-green-cyan) !important;
}

.has-vivid-green-cyan-border-color {
  border-color: var(--wp--preset--color--vivid-green-cyan) !important;
}

.has-pale-cyan-blue-border-color {
  border-color: var(--wp--preset--color--pale-cyan-blue) !important;
}

.has-vivid-cyan-blue-border-color {
  border-color: var(--wp--preset--color--vivid-cyan-blue) !important;
}

.has-vivid-purple-border-color {
  border-color: var(--wp--preset--color--vivid-purple) !important;
}

.has-vivid-cyan-blue-to-vivid-purple-gradient-background {
  background: var(--wp--preset--gradient--vivid-cyan-blue-to-vivid-purple) !important;
}

.has-light-green-cyan-to-vivid-green-cyan-gradient-background {
  background: var(--wp--preset--gradient--light-green-cyan-to-vivid-green-cyan) !important;
}

.has-luminous-vivid-amber-to-luminous-vivid-orange-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange) !important;
}

.has-luminous-vivid-orange-to-vivid-red-gradient-background {
  background: var(--wp--preset--gradient--luminous-vivid-orange-to-vivid-red) !important;
}

.has-very-light-gray-to-cyan-bluish-gray-gradient-background {
  background: var(--wp--preset--gradient--very-light-gray-to-cyan-bluish-gray) !important;
}

.has-cool-to-warm-spectrum-gradient-background {
  background: var(--wp--preset--gradient--cool-to-warm-spectrum) !important;
}

.has-blush-light-purple-gradient-background {
  background: var(--wp--preset--gradient--blush-light-purple) !important;
}

.has-blush-bordeaux-gradient-background {
  background: var(--wp--preset--gradient--blush-bordeaux) !important;
}

.has-luminous-dusk-gradient-background {
  background: var(--wp--preset--gradient--luminous-dusk) !important;
}

.has-pale-ocean-gradient-background {
  background: var(--wp--preset--gradient--pale-ocean) !important;
}

.has-electric-grass-gradient-background {
  background: var(--wp--preset--gradient--electric-grass) !important;
}

.has-midnight-gradient-background {
  background: var(--wp--preset--gradient--midnight) !important;
}

.has-small-font-size {
  font-size: var(--wp--preset--font-size--small) !important;
}

.has-medium-font-size {
  font-size: var(--wp--preset--font-size--medium) !important;
}

.has-large-font-size {
  font-size: var(--wp--preset--font-size--large) !important;
}

.has-x-large-font-size {
  font-size: var(--wp--preset--font-size--x-large) !important;
}

.wp-block-navigation a:where(:not(.wp-element-button)) {
  color: inherit;
}

:where(.wp-block-post-template.is-layout-flex) {
  gap: 1.25em;
}

:where(.wp-block-post-template.is-layout-grid) {
  gap: 1.25em;
}

:where(.wp-block-columns.is-layout-flex) {
  gap: 2em;
}

:where(.wp-block-columns.is-layout-grid) {
  gap: 2em;
}

.wp-block-pullquote {
  font-size: 1.5em;
  line-height: 1.6;
}