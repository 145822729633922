.info-tag {
    border: solid 1px var(--bs-border-color);
    border-radius: var(--bs-border-radius-pill) !important;
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    color: #6c757d
}

.accordion-body {
    padding: 0px;
}


.full-screen {
    padding: 20px 20px !important;
    z-index: 1000 !important;
    position: fixed !important;
    background: #fff !important;
    top: 0 !important;
    left: 0 !important;
    height: -webkit-fill-available !important;
    max-height: none !important;
    width: -webkit-fill-available !important;
}

.hover:hover {
    background: rgba(0, 0, 0, 0.05);
}

/*.MuiFormLabel-root {*/
/*    font-size: 16px !important;*/
/*}*/